import "./App.css";
import Header from "./components/header";

import PrivacyPolicy from "./components/PrivacyPolicy";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import CookieInformation from "./pages/CookieInformation";
import CaliforniaPrivacyNotice from "./components/CaliforniaPrivacyNotice";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route
            exact
            path="/cookie-information-opt-out/"
            element={<CookieInformation />}
          />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/privacy-notice-for-california-residents"
            element={<CaliforniaPrivacyNotice />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
